// import { auto } from '@popperjs/core';
// import Swiper, { Navigation, Pagination, Thumbs} from 'swiper';
// Swiper.use([Navigation, Pagination, Thumbs]);

const swiper = new Swiper('.certificates__slider', {
  slidesPerView: 4,
  spaceBetween: 29,
  loop: true,
  centeredSlides: false,
  navigation: {
    nextEl: '.certificates__btn-next',
    prevEl: '.certificates__btn-prev',
  },
  pagination: {
    el: ".certificates__pagination",
    type: "fraction",
  },
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    550: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    800: {
      slidesPerView: 4,
      spaceBetween: 29,
    },

  }
});

const teamSwiper = new Swiper('.team-slider__swiper', {
  slidesPerView: 3,
  spaceBetween: 30,
  loop: true,
  centeredSlides: false,
  navigation: {
    nextEl: '.team-slider__btn-next',
    prevEl: '.team-slider__btn-prev',
  },
  pagination: {
    el: ".team-slider__pagination",
    type: "fraction",
  },
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    550: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    650: {
      spaceBetween: 20,
    },
    800: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  }
});


const portfolioSwiper = new Swiper('.portfolio__slider', {
  slidesPerView: 1,
  loop: true,
  simulateTouch: false,
  spaceBetween: 10,
  navigation: {
    nextEl: '.portfolio__btn-next',
    prevEl: '.portfolio__btn-prev',
  },
  pagination: {
    el: ".portfolio__pagination",
    type: "fraction",
  },
});


const thumbsFirst = new Swiper(".portfolio-thumbs__slider-first", {
  spaceBetween: 10,
  slidesPerView: 3,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  centeredSlides: true,
  // миниатюры (превью)
	thumbs: {
		swiper: {
			el: ".portfolio__slider-first",
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
		}
	},
});


const thumbsSecond = new Swiper(".portfolio-thumbs__slider-second", {
  spaceBetween: 10,
  slidesPerView: 3,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  centeredSlides: true,
  // миниатюры (превью)
	thumbs: {
		swiper: {
			el: ".portfolio__slider-second",
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
		}
	}
});

// const swiperSecond = new Swiper('.portfolio__slider-second', {
//   slidesPerView: 1,
//   spaceBetween: 10,
//   loop: true,
// });

// const thumbsSecond = new Swiper(".portfolio-thumbs__slider-second", {
//   spaceBetween: 10,
//   slidesPerView: 3,
//   loop: true,
//   navigation: {
//     nextEl: '.swiper-button-next',
//     prevEl: '.swiper-button-prev',
//   },
//   centeredSlides: true,
// 	thumbs: {
//     swiper: swiperSecond
//   }
// });




