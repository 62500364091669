// ввод только цифры
document.querySelectorAll('.modal-tel').forEach(elem => {
  elem.addEventListener('input', function (e) {
    let target = e.target;
    target.value = target.value.replace(/[^+\d]/g, '');
  });
})

// переключение tab
const contactBtn = document.querySelectorAll('.contact__btn');
const contactContent = document.querySelectorAll('.contact__content');

contactBtn.forEach(item => {
  item.addEventListener('click', () => {
    //добавляем класс кнопке
    contactBtn.forEach(item => {
      item.classList.remove('active');
    })
    item.classList.add('active');

    //добавляем класс контенту
    let attrBtn = item.getAttribute('data-btn');
    contactContent.forEach(elem => {
      elem.classList.remove('active');
      let attrInf = elem.getAttribute('data-inf');
      if(attrBtn === attrInf) {
        elem.classList.add('active');
      }
    })
  })
})

//add / close team slide
function addTeamSlider() {
  const teamItem = document.querySelectorAll('.team__item');
  const teamSection = document.querySelector('.team');
  const teamSliderSection = document.querySelector('.team-slider');

  if(teamItem.length <= 6) {
    teamSliderSection.classList.add('desactive')
    teamSection.setAttribute('id', 'team');
  } else {
    teamSection.classList.add('desactive')
    teamSliderSection.classList.remove('desactive')
    teamSection.removeAttribute('id')
    teamSliderSection.setAttribute('id', 'team');
  }

  if(document.documentElement.clientWidth < 550) {
    teamSection.classList.add('desactive')
    teamSliderSection.classList.remove('desactive')
    teamSection.removeAttribute('id')
    teamSliderSection.setAttribute('id', 'team');
  }

}
addTeamSlider();

// открываем модальное окно
function openModal(){
  const btnConsult = document.querySelectorAll('.btn-consult');
  const modalCall = document.querySelector('.modal-call');
  const modalClose = document.querySelectorAll('.modal__close');
  const modal = document.querySelector('.modal');

  btnConsult.forEach(elem => {
    elem.addEventListener('click', () => {
      modalCall.classList.add('active');
    })
  });

  modalClose.forEach(elem => {
    elem.addEventListener('click', () => {
      modalCall.classList.remove('active');
    })
  });

  modal.addEventListener('click', (event) => {
    let target = event.target;
    if(target.matches(".modal")) {
      modalCall.classList.remove('active');
    }
  })
}
openModal();

// скролл menu-fixed
const menuFixed = document.querySelector('.header__fixed');

window.addEventListener('scroll', function () {
  if(window.scrollY > 150) {
    menuFixed.classList.add('active');
  } else {
    menuFixed.classList.remove('active')
  }
})

document.querySelectorAll('a[href^="#"]').forEach(item => {
  item.addEventListener('click', function (e) {
      e.preventDefault();
      // if(this.getAttribute('href') === '')

      document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
      });
  });
});

// burger
document.querySelector('.header__burger').addEventListener('click', function() {

  document.querySelector('.header__nav-mobile').classList.add('active');
  document.querySelector('.header__nav-mobile--wrapper').classList.add('active');
})

document.querySelector('.header__nav-mobile--close').addEventListener('click', function() {
  document.querySelector('.header__nav-mobile').classList.remove('active');
  document.querySelector('.header__nav-mobile--wrapper').classList.remove('active');

})

const headerContent = document.querySelector('.header__nav-mobile--content');
headerContent.addEventListener('click', (event) => {
  let target = event.target;
  if(target.classList.contains('nav__link') || target.classList.contains('btn-light')) {
    document.querySelector('.header__nav-mobile').classList.remove('active');
    document.querySelector('.header__nav-mobile--wrapper').classList.remove('active');
  }
})

// open phone mobile click
const contactPhone = document.querySelector('#contact-phone');
const headerContact = document.querySelector('.header__contact-content');
const headerContactContent = document.querySelector('#contact-content');
const mainSection =  document.querySelector('.main__section');

contactPhone.addEventListener('click', () => {
  contactPhone.classList.toggle('active');
  headerContactContent.classList.toggle('active');
  mainSection.classList.toggle('active');
})

//close buttons certificates
function closeCertificatesBtn() {
  const certificatesItem = document.querySelectorAll('.certificates__item');
  const certificatesBtn = document.querySelector('.certificates__btn-wrapper');

  if(certificatesItem.length <= 4) {
    certificatesBtn.classList.add('desactive')
  } else {
    certificatesBtn.classList.remove('desactive')
  }

}
closeCertificatesBtn();



// change lang
function changeLanguage(){
  const headerWrap = document.querySelectorAll('.header__contact-language--wrap');


  headerWrap.forEach(item => {
    item.addEventListener('click', (event) => {
      const headerLanguage = item.querySelector('.header__contact-language');
      const headerLanguageRu = item.querySelector('.header__contact-language--ru');
      const headerLanguageZh = item.querySelector('.header__contact-language--zh');
      let target = event.target;


      headerLanguageZh.classList.toggle('active');
      headerLanguage.classList.toggle('active');
      if(target.classList.contains('header__contact-language--zh')) {
        if(target.textContent == 'ZH') {
          headerLanguageRu.textContent = target.textContent;
          target.textContent = 'RU';
        } else if (target.textContent == 'RU') {
          headerLanguageRu.textContent = target.textContent;
          target.textContent = 'ZH';
        }
      }
    })
  })


}
changeLanguage();


// document.querySelectorAll('a[href^="#"').forEach(link => {

//   link.addEventListener('click', function(e) {
//       e.preventDefault();

//       let href = this.getAttribute('href').substring(1);

//       const scrollTarget = document.getElementById(href);

//       const topOffset = document.querySelector('.header__mobile').offsetHeight;
//       // const topOffset = 0; // если не нужен отступ сверху
//       const elementPosition = scrollTarget.getBoundingClientRect().top;
//       const offsetPosition = elementPosition - topOffset;

//       window.scrollBy({
//           top: offsetPosition,
//           behavior: 'smooth'
//       });
//   });
// });
